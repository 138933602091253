import _isExtendable from "is-extendable";
import _arrUnion from "arr-union";
import _getValue from "get-value";
import _setValue from "set-value";
var exports = {};
var isObject = _isExtendable;
var union = _arrUnion;
var get = _getValue;
var set = _setValue;

exports = function unionValue(obj, prop, value) {
  if (!isObject(obj)) {
    throw new TypeError("union-value expects the first argument to be an object.");
  }

  if (typeof prop !== "string") {
    throw new TypeError("union-value expects `prop` to be a string.");
  }

  var arr = arrayify(get(obj, prop));
  set(obj, prop, union(arr, arrayify(value)));
  return obj;
};

function arrayify(val) {
  if (val === null || typeof val === "undefined") {
    return [];
  }

  if (Array.isArray(val)) {
    return val;
  }

  return [val];
}

export default exports;